import moment from "moment";
import axios from "axios";
import React, { useState, useRef, useContext, useEffect } from "react";
import { Store } from '../utils/Store';

import {NotificationContainer, NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';

import {FilterLine, FilterButton, FilterTextInput, FilterButtonSmall, FilterTextLabel, FilterCheckbox, FilterDropdownInput} from "../components/filter/Filter";
import PageTable from "../components/pageTable/PageTable";
import {Popup, PopupInputText, PopupButtonBig, PopupButtonSmall} from "../components/popup/Popup";

export default function ChallengePage() {
  // Authentication 
  const loginToken =  localStorage.getItem("parttimestydy_admin__access__token");
  const { state, dispatch } = useContext(Store);

  /////////////
  // Get List
  /////////////

  // Filter
  const [filterPageNum, setFilterPageNum] = useState(1);
  const [filterPageSize, setFilterPageSize] = useState(300);

  // ResultData
  const [resultDatas, setResultDatas] = useState([]);
  const [resultDataCount, setResultDataCount] = useState(0);
  const [resultDataPageNum, setResultDataPageNum] = useState(0);
  const [resultDataPageCount, setResultDataPageCount] = useState(0);

  // View Option
  const [viewOptionNum, setViewOptionNum] = useState(0);

  // View -> Filter
  const FilterComponent = <div style={{borderRadius:"10px", padding:"10px 10px", boxShadow:"0px 1px 5px lightgray"}}>
    <FilterLine>
      <FilterButton onClick={()=>{updateResultData()}}>조회</FilterButton>  
    </FilterLine>
  </div>

  const filterPageComponent = <>
    <FilterLine justifyContent="center" >
      <FilterButtonSmall onClick={()=>{if(filterPageNum == 1 || filterPageNum == 2) return; setFilterPageNum(filterPageNum-2);}}>&lt;&lt;</FilterButtonSmall>  
      <FilterButtonSmall onClick={()=>{if(filterPageNum == 1) return; setFilterPageNum(filterPageNum-1);}}>&lt;</FilterButtonSmall>  
      <FilterTextLabel> {resultDataPageNum} / {resultDataPageCount} </FilterTextLabel>        
      <FilterButtonSmall onClick={()=>{if(filterPageNum >= resultDataPageCount) return; setFilterPageNum(filterPageNum+1);}}>&gt;</FilterButtonSmall>  
      <FilterButtonSmall onClick={()=>{if(filterPageNum >= resultDataPageCount-1) return; setFilterPageNum(filterPageNum+2);}}>&gt;&gt;</FilterButtonSmall>  
    </FilterLine>
  </>

  // Filter -> ResultData
  function updateResultData() {
    let filterQuery = `?pageSize=${filterPageSize}&pageNum=${parseInt(filterPageNum) - 1}`;
    let url = `${process.env.REACT_APP_SERVER_ROOT_URL}/v2.0/admin/variable` + filterQuery;
    if (state.userToken == "" && loginToken != "") state.userToken = loginToken
    axios({ method: 'GET', url: url,
      headers: { "Authorization" : "Bearer " + state.userToken, "Content-Type": `application/json` }, 
      data: null,
    }).then(res => {
      if (res && res.data ) {
        setResultDatas(res.data);
        setResultDataCount(res.data.length);
        setResultDataPageCount(1);
        setResultDataPageNum(filterPageNum);
      }
      else NotificationManager.error(res.message, "오류발생 ["+res.status+"] ");
      console.log(res.data);
    })
    .catch(e=> { NotificationManager.error(e.message, "오류 발생!");})
  }
  useEffect(()=> {if(filterPageNum == 1) updateResultData(); else setFilterPageNum(1)},[filterPageSize ])
  useEffect(()=> {updateResultData()},[filterPageNum])
  

  const defaultThList = [ "번호","변수 명","변수 값", "상세"]
  const defaultDataKeyList = [ "idx", "name", "value", "detail"]
  // ResultData -> View
  const resultDataComponent = <>
    {`총 데이터 수 : ${resultDataCount}, 총 페이지 수 : ${resultDataPageCount}`}
    <PageTable
      thList={
        viewOptionNum == 0 ? defaultThList
        : defaultThList
      }
      dataKeyList={
        viewOptionNum == 0 ? defaultDataKeyList
        : defaultDataKeyList
      }
      dataList={resultDatas.map((value) => {
        value.value = value.type == "IMAGE_URL" ? (<img src={value.data} style={{ width: `100px`, height: `100px`, objectFit: 'contain', }} />) : value.data
        value.detail = (<div className="button" onClick={() => { openResultDataSelectedPopup(value) }}> . </div>);
        return value;
      })}
    />
  </>


  /////////////
  // By One
  /////////////
  const [resultDataSelected, setResultDataSelected] = useState({});
  const [selectedPopupInputValue, setSelectedPopupInputValue] = useState("");

  async function openResultDataSelectedPopup(value) {
    setSelectedPopupInputValue(""); 
    setResultDataSelected(value);
  }
  function closeSelectedPopupAndRefresh(){ setResultDataSelected({}); updateResultData(); }


  function updateStringValue() {
    var url1 = `${process.env.REACT_APP_SERVER_ROOT_URL}/v2.0/admin/variable/${resultDataSelected.idx}`;

    var formData1 = new FormData();
    if(resultDataSelected.type != "STRING") {
      NotificationManager.error("타입이 STRING인 변수만 수정할 수 있습니다.", "지원하지 않는 기능"); 
      return;
    }
    formData1.append("stringValue", selectedPopupInputValue);
    formData1.append("name", resultDataSelected.name );
    formData1.append("variableType", resultDataSelected.type.replace("_URL", "")); 
    if (state.userToken == "" && loginToken != "") state.userToken = loginToken
    axios({
      method: 'PUT', url: url1,
      headers: { "Authorization": "Bearer " + state.userToken, "Content-Type": `application/json` },
      data: formData1,
    }).then(res => {
      if (res.status < 200 || res.status >= 300) { NotificationManager.error(res.message, "오류발생 [" + res.status + "] "); return; }
      closeSelectedPopupAndRefresh();
    })
      .catch(e => { NotificationManager.error(e.message, "오류발생"); })
  }
  // Data -> View 
  const selectedPopUp = <Popup enabled={Object.keys(resultDataSelected).length} closeCallback={()=>{closeSelectedPopupAndRefresh()}}>
    <PopupInputText name="번호" value={resultDataSelected.idx}></PopupInputText> <br/>
    <PopupInputText name="타입" value={resultDataSelected.type} /> <br/>
    <PopupInputText name="변수명" value={resultDataSelected.name} /> <br/>
    <PopupInputText name="변수값" width={"0px"}/> <br/>
    {
    resultDataSelected.type == "IMAGE_URL" 
      ? <img src={resultDataSelected.data} style={{ maxWidth: "100%", maxHeight:"100%", objectFit: 'contain', }} />
      : <textarea style={{marginInline:"auto", width:"100%", height: "60px", boxSizing:"border-box", fontSize:"16px"}} value={resultDataSelected.data}></textarea>  
    }
    <br/>
    <PopupInputText name="변수값 변경" width={"0px"} /> <br />
    <textarea style={{ marginInline: "auto", width: "100%", height: "60px", boxSizing: "border-box", fontSize: "16px" }} placeholder={resultDataSelected.data}
      value={selectedPopupInputValue} onChange={e=>{setSelectedPopupInputValue(e.target.value)}}
    ></textarea>  
    <PopupButtonBig onClick={()=>{updateStringValue();}}> 변경 </PopupButtonBig>

    {/* <PopupButtonBig > 삭제 </PopupButtonBig> */}
  </Popup>


  /////////////
  // Create One
  /////////////

  return (
  <>
    {FilterComponent}
    <div style={{height:"20px"}}></div>
    {resultDataComponent} 
    <div style={{height:"10px"}}></div>
    {filterPageComponent}  
    {selectedPopUp} 
    <NotificationContainer />
  </>
  );
}
