import React, { Component, useState, useEffect, useRef} from 'react';

import styled from "styled-components";
import Calendar from "../others/SelectorCalendar";
import DropDownClickable from "../others/DropDownClickable";
import moment from "moment";

const FilterTextInput = styled.input`
  margin-inline: 4px; width: ${props=>props.width || '120px'}; 
  border: none;
  border-bottom: 1px solid #E0E0E0; 
  outline: none;
  &::placeholder { color: rgba(0, 0, 0, 0.3); font-size: 12px; user-select: none; }
`;

const FilterButton = styled.div`
  margin-inline: 4px; min-width: 60px; height: 24px; padding-inline: 10px;
  background-color:  #8D8EAC; color: #fff; border-radius: 8px; font-size: 14px; cursor: pointer; box-shadow: none;
  display: flex; align-items: center; justify-content: center;
`;

const FilterButtonSmall = styled.div`
  background: #8D8EAC; border-radius: 6px; height: 24px; min-wdith: 24px; align-items:center; margin-inline: 2px;
  color: #fff; font-size: 14px; cursor: pointer; padding-inline: 6px;
`;

const FilterLine = styled.div`
  margin-top: 8px;
  width: auto; display: flex; flex-direction : row; justify-content: ${props=>props.justifyContent || "flex-start"}; align-items: center;
`;

const FilterTextLabel = styled.div`
  color: #1c1c1c; font-size: 14px; padding-inline: 4px;
`

function FilterCheckbox({ children, disabled, checked, onChange }) {
  return (
    <label style={{fontSize: '14px', color: '#1c1c1c', marginInline:"2px"}}>
      <input type="checkbox" disabled={disabled} checked={checked} 
      onChange={({ target: { checked } }) => onChange(checked)}
      />
      {children}
    </label>
  );
}

function FilterDropdownInput({width, options, value, onChange, placeholder}){
  return (
      <DropDownClickable
        value={
          <FilterTextInput value={value} type="text" placeholder={placeholder}
            width={width}
            onChange={(e)=>{}}
            onFocus={e=>{}}
            onKeyPress={(e) => {}}
          />
        }
        // style={{ width: `100%` }}
        options={
          <>
            {options.map((o)=>{
              return (<div onClick={(e)=>{onChange({target:{value:o}})}}>{o==""?"-":o}</div>) 
            })}
          </>
        }
      />
  );
}



function FilterDateInput({ value, onChange, placeholder}){
  const [boolShowOption, setBoolShowOption] = useState(false);
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);
  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setBoolShowOption(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  return (
    <div ref={wrapperRef}>
      <FilterTextInput
        type="text" placeholder={placeholder}
        value={value} 
        onChange={e => {onChange(e)}}
        // onFocus={e => {showCalendar = !showCalendar; console.log(showCalendar);}}
        onClick={e => {setBoolShowOption(true)}}
      >
      </FilterTextInput>
      {
        boolShowOption && <div className="" style={{ position: `absolute`, zIndex: 9999, backgroundColor: `#fff` }}>
          <Calendar
            defaultYear={moment().format("YYYY")}
            defaultMonth={moment().format("MM")}
            defaultDate={moment().format("DD")}
            callbackYear={value => {}}
            callbackMonth={value => {}}
            callbackDate={value => {console.log(value);onChange({target:{value:value}})}}
          ></Calendar>
        </div>
      }
    </div>

  )
}

export {  
  FilterTextInput,FilterButton, FilterButtonSmall, FilterLine, FilterTextLabel, 
  FilterCheckbox, FilterDropdownInput, 
  FilterDateInput 
};