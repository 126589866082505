import "./pageTable.css";


export default function PageTable({ thList, dataKeyList, dataList}) {
  return (
    // <div className="chart">
    //   <h3 className="chartTitle">{title}</h3>
    //   <ResponsiveContainer width="100%" aspect={4 / 1}>
    //     <LineChart data={data}>
    //       <XAxis dataKey="name" stroke="#5550bd" />
    //       <Line type="monotone" dataKey={dataKey} stroke="#5550bd" />
    //       <Tooltip />
    //       {grid && <CartesianGrid stroke="#e0dfdf" strokeDasharray="5 5" />}
    //     </LineChart>
    //   </ResponsiveContainer>
    // </div>
    <table className="pageTable">
      <tr className="">{thList.map((thItem, thIndex)=>{return (<th>{thItem}</th>)})}</tr>
      {dataList.map((dataRow, dataIndex)=>{
          return(<tr className={dataRow.trClassName}>
            {dataKeyList.map((dataKey, dataKeyIndex)=>{
              return (<td >{dataRow[dataKey]}</td>)
            })}
          </tr>)
        })}
{/*       
    {
      arrayPageData &&
      arrayPageData.map((arrayItem, arrayIndex)=> {
        return (
          <PageItem 
            className={"view_item_row"}
            onClick={()=> {
              setSelectedPageData(arrayItem);
              setOpenPopup(true);
            }}
          >
          <td className={"div0 "}>{arrayItem.idx}</td>
          <td className={"div0 "}>{arrayItem.dailyIdx}</td>
          <td className={"div0 "}>{arrayItem.date}</td>
          <td className={"div0 "}>{arrayItem.orderInProject + "/" + arrayItem.projectScheduleCount}</td>
          <td className={"div0 "}>{arrayItem.userName}</td>
          <td className={"div0 "}>{arrayItem.userPhone}</td>
          <td className={"div0 "}>{arrayItem.goalMinuteToStudy}</td>
          <td className={"div0 "}>{arrayItem.studyMinute}</td>
          <td className={"div0 "}>{arrayItem.achievementRate}</td>
          <td className={"div0 "}>{arrayItem.recordCount}</td>
          <td className={"div0 "}>{arrayItem.reanalyzeRequested == true ? <>신청됨</> : <>-</>}</td>
          </PageItem>
        )
      })
    } */}
    </table>
  );
}
