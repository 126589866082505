import "./sidebar.css";
import {
  LineStyle,
  Timeline,
  TrendingUp,
  PermIdentity,
  Storefront,
  AttachMoney,
  BarChart,
  MailOutline,
  DynamicFeed,
  ChatBubbleOutline,
  WorkOutline,
  Report,
} from "@material-ui/icons";
import { Link, useHistory } from "react-router-dom";
import React, { useState, useRef, useContext, useEffect } from "react";

export default function Sidebar(props) {

  const [selectedMenuIdx, setSelectedMenuIdx] = useState("");

  useEffect(() => {
    for (var i = 0; i < menuAll.length; i++) {
      if (menuAll[i].pathname == window.location.pathname) {
        setSelectedMenuIdx(menuAll[i].idx);
      }
    }
  }, [])

  const history = useHistory()
  useEffect(() => {
    return history.listen((location) => {
      for (var i = 0; i < menuAll.length; i++) {
        if (menuAll[i].pathname == window.location.pathname) {
          setSelectedMenuIdx(menuAll[i].idx);
        }
      }
    })
  }, [history])


  const menuAll = [
    { idx: 2023071701, label: '이용자', pathname: '/v2/user' }, // 이용자 관리, 이용자 관리 (생일)
    // { idx: 2023071702, label: '챌린지 내역', pathname: '/v2/challenge' }, // 결제 및 취소 데이터, 결제 데이터
    { idx: 2023071703, label: '일자별 진행률', pathname: '/v2/challenge-daily' }, // 일별 진행 관리, 일별 진행 관리 (IP)
    { idx: 2023071704, label: '촬영 기록', pathname: '/v2/record' }, // 진행 중 영상관리, 촬영 완료 영상 관리, 촬영 완료 영상 관리 (IP), 데이터용 영상 관리, 재검수 영상 관리
    { idx: 2023071705, label: '출금 요청', pathname: '/v2/credit-withdrawal' }, // 출금 관리

    // { idx: 2023071706, label: '탈퇴 의견', pathname: '/v2/user-deleted' }, //탈퇴한 이용자 관리
    { idx: 2023071707, label: '상금 내역', pathname: '/v2/credit-history' }, // 이용자 크래딧 내역
    // { idx: 2023071708, label: '챌린지 쿠폰', pathname: '/v2/challenge-coupon' }, // 쿠폰 쿠폰 쿠폰
    // { idx: 2023071711, label: '스터디그룹', pathname: '/v2/study-group' }, // 스터디 그룹
    // { idx: 2023071801, label: '푸시메시지', pathname: '/v2/push-message' }, // 푸시메시지 전송
    // { idx: 2023071803, label: '환경 변수', pathname: '/v2/variable' }, // 변수 관리
    // { idx: 2023071802, label: '지표', pathname: '/v2/statistic' }, // (구) 통계 관리, 통계 수치 조회
  ]

  const menuV2Main = [
    { idx: 2023071701, label: '이용자', pathname: '/v2/user' }, // 이용자 관리, 이용자 관리 (생일)
    // { idx: 2023071702, label: '챌린지 내역', pathname: '/v2/challenge' }, // 결제 및 취소 데이터, 결제 데이터
    { idx: 2023071703, label: '일자별 진행률', pathname: '/v2/challenge-daily' }, // 일별 진행 관리, 일별 진행 관리 (IP)
    { idx: 2023071704, label: '촬영 기록', pathname: '/v2/record' }, // 진행 중 영상관리, 촬영 완료 영상 관리, 촬영 완료 영상 관리 (IP), 데이터용 영상 관리, 재검수 영상 관리
    { idx: 2023071705, label: '출금 요청', pathname: '/v2/credit-withdrawal' }, // 출금 관리
  ]


  const menuV2Sub = [
    // { idx: 2023071711, label: '스터디그룹', pathname: '/v2/study-group' }, // 스터디 그룹
    // { idx: 2023071708, label: '챌린지 쿠폰', pathname: '/v2/challenge-coupon' }, // 쿠폰 쿠폰 쿠폰
    { idx: 2023071707, label: '상금 내역', pathname: '/v2/credit-history' }, // 이용자 크래딧 내역
    // { idx: 2023071706, label: '탈퇴 의견', pathname: '/v2/user-deleted' }, //탈퇴한 이용자 관리
    // { idx: 2023071801, label: '푸시메시지', pathname: '/v2/push-message' }, // 푸시메시지 전송
    // { idx: 2023071803, label: '환경 변수', pathname: '/v2/variable' }, // 변수 관리
  ]
  const menuV2External = [
    // { idx: 2023071802, label: '실시간 지표', pathname: '/v2/external-link?link=' },
    // { idx: 2023071802, label: '서버 상태', pathname: '/v2/external-link?link=' },
    { idx: 2023072801, label: 'Grafana', pathname: 'http://grafana.partimestudy.com/dashboards' },
    // { idx: 2023072802, label: 'GA', pathname: 'https://analytics.google.com/analytics/web/#/p291506918/reports/reportinghub?params=_u..nav%3Dmaui' },
    // { idx: 2023072803, label: 'Firebase', pathname: 'https://console.firebase.google.com/project/pts-progject/overview?hl=ko' },
    // { idx: 2023072804, label: 'Bigquery', pathname: 'https://console.cloud.google.com/bigquery?project=pts-progject'}
  ]

  return (
    <>
      {props.is_login == false && <></>}
      {props.is_login == true &&
        <div className="sidebar">
          <div className="sidebarWrapper">
            <div className="sidebarMenu">
              <div style={{height: '8px'}}></div>
              <div className="sidebarTitle">Main</div>
              <ul className="sidebarList">{
                menuV2Main.map((menuItem, menuIndex) => {
                  return (
                    <Link to={menuItem.pathname} className="link">
                      <li className={"sidebarListItem " + (selectedMenuIdx == menuItem.idx ? " selected" : "")}>{menuItem.label}</li>
                    </Link>
                  )
                })
              }</ul>
            </div>
            <div className="sidebarMenu">
              <div className="sidebarTitle">Sub</div>
              <ul className="sidebarList">{
                menuV2Sub.map((menuItem, menuIndex) => {
                  return (
                    <Link to={menuItem.pathname} className="link">
                      <li className={"sidebarListItem " + (selectedMenuIdx == menuItem.idx ? " selected" : "")}>{menuItem.label}</li>
                    </Link>
                  )
                })
              }</ul>
            </div>
            <div className="sidebarMenu">
              <div className="sidebarTitle">External</div>
              <ul className="sidebarList">{
                menuV2External.map((menuItem, menuIndex) => {
                  return (
                      <li
                      className={"sidebarListItem " + (selectedMenuIdx == menuItem.idx ? " selected" : "")}
                      onClick={()=>{window.open(menuItem.pathname)}}
                      >
                        {menuItem.label}
                      </li>
                  )
                })
              }</ul>
            </div>
          </div>
        </div>
      }
    </>
  );
}
