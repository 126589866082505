import styled from "styled-components";

const Popup = ({ children, enabled, closeCallback }) => {
  return (<>
    <div className="PopupWrapper"
      onClick={() => { closeCallback() }}
      style={{
        zIndex: '998', position: 'fixed', left: 0, top: 0, width: '100vw', height: '100vh', background: '#0008',
        display: enabled ? 'flex' : 'none', alignItems: 'center', justifyContent: 'center'
      }}
    >
      <div className="Popup"
        onClick={e => {e.stopPropagation();e.preventDefault();}}
        style={{
          zIndex: '999', minWidth: '300px', minHeight: '100px', maxHeight: "80%", overflowY:"auto",
          background: 'white', boxShadow: '2px 4px 12px rgba(28, 28, 30, 0.1)', borderRadius: '16px',
          padding: "10px", color: "black"
        }}
      >
        <div className="PopupCloseBtn"
          onClick={() => { closeCallback() }}
          style={{ cursor: 'pointer', color: "black", textAlign: 'right' }}
        >&#x2715;</div>
        {children}
      </div>
    </div>
  </>
  )
}

const TextInputStyled = styled.input`
  font-size: ${props=>props.fontSize || '16px'};
  margin: 4px; width: ${props=>props.width || '300px'}; 
  color: ${props => props.color || 'black'};
  border: none;
  border-bottom: 1px solid #E0E0E0; 
  outline: none;
  &::placeholder { color: rgba(0, 0, 0, 0.3); font-size: 12px; user-select: none; }
`;
function PopupInputText( {name, value, width, placeholder, onChange , fontSize, color}) {
  return (
    <label style={{fontSize: fontSize || '16px', color: '#1c1c1c'}}>
      {name} : <TextInputStyled type="text" value= {value} onChange={onChange} width={width} placeholder={placeholder} fontSize ={fontSize} color={color}/>
    </label>
  );
}

const PopupButtonBig = styled.div`
  box-sizing: border-box;
  width: 100%; background: #8d8dad; border-radius: 12px; cursor: pointer;
  padding: 5px 10px;
  font-size: 16px; color : white; text-align: center;
`;

const PopupButtonSmall = styled.div`
  background: #8D8EAC; border-radius: 6px;  min-wdith: 24px; width: fit-content; align-items:center; margin-inline: 2px;
  padding: 4px 5px; display: inline-block;
  ${props=>props.unselected?"color: #ccc; ":"color: #fff; "}
  
  cursor: pointer; padding-inline: 6px;
  font-size: ${props=>props.fontSize || '14px'};

`;



export {Popup, PopupInputText, PopupButtonBig, PopupButtonSmall};