import React, { Component, useState, useEffect, useRef} from 'react';
import styled from 'styled-components';

const This = styled.div`

    position: relative;


    .dropbtn {
        color: white;
        font-size: 16px;
        border: none;
        cursor: pointer;
        background-color: #fff;
    }

    .dropbtn:hover, .dropbtn:focus {
        
    }

    .dropdown {
        position: relative;
        display: inline-block;
    }

    .dropdown-content {
        display: none;
        position: absolute;
        background-color: #ffffff;
        min-width: 160px;
        overflow: auto;
        box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
        z-index: 1;
    }

    .dropdown-content a {
        color: black;
        padding: 12px 16px;
        text-decoration: none;
        display: block;
    }

    .dropdown a:hover {background-color: #ddd;}
    // .show {display: block;}

`;




const Comp = (props) => { 
    const { children} = props; 
    const [boolShowOption, setBoolShowOption] = useState(false);
    
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);
    /**
     * Hook that alerts clicks outside of the passed ref
     */
    function useOutsideAlerter(ref) {
        useEffect(() => {
            /**
             * Alert if clicked on outside of element
             */
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    // alert("You clicked outside of me!");
                    setBoolShowOption(false);
                }
            }
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }


    return (
        <This  {...props}>

        <div className="dropdown" ref={wrapperRef}>
            <button 
                className="dropbtn" 
                onClick={()=> {
                    // myFunction();
                    setBoolShowOption(!boolShowOption);
                }}
            >
                {props.value}
            </button>
            <div 
                id="myDropdown" 
                className="dropdown-content"
                style={{ display: boolShowOption == true ? 'block' : 'none'}}
            >
                {props.options}
            </div>
        </div>
        </This>
    ) 
};

export default Comp;
