
import moment from "moment";
import React, { Component } from "react";
import Select, { components, StylesConfig } from "react-select";
import styled from "styled-components";


const Calendar1 = styled.div`

width: 100%;
min-width: 100%;
min-height: 260px;
overflow : hidden;

border: 1px solid #E0E0E0;
border-radius: 4px;
z-index: 999;

.calendar-select-wrapper1 {  
                
    width: calc(100% - 4px);    
    min-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin-top: 8px;
    margin-left: auto;
    margin-right: auto;
    background-color : #fff;
}

ul {list-style-type: none;}
.month {
  margin: 0;
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #E0E0E0;
  min-height: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.month ul {
  margin: 0;
  padding: 0;
  width: 100%;
  width: 100%;
  min-height: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  justify-content: center;
}

.month ul li {
  font-size: 12px;  
  text-transform: uppercase;
}
.month .prev {
  position: absolute;
  left: 8px;
}
.month .next {
    position: absolute;
    right: 8px;
}
.weekdays {
  border-bottom: 1px solid #E0E0E0;
  margin: 0;
  padding: 0;
  width: 100%;
  min-width: 100%;
  min-height: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.weekdays li {
  display: inline-block;
  width: calc(100% / 7);
  min-width: calc(100% / 7);

  text-align: center;
  font-size: 10px;
}

.days {
  padding: 10px 0;
  margin: 0;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
}

.days li {
  list-style-type: none;
  display: inline-block;
  width: calc(100% / 7);
  min-width: calc(100% / 7);
  margin-bottom: 5px;
  text-align: center;
  min-height: 24px;
  font-size: 10px;
}

.days li .normal {
  padding: 5px;
}


.days li .active {
  padding: 5px;
  background: #8d8dad;
  color: white !important;
  border: 1px solid #aeaeae;
  border-radius: 4px;
}

`;

  
export default class Selector extends Component {
  constructor(props) {
    super(props);
    this.state = {
        CurrentYear : "",
        CurrentMonth : "",

        MonthArray : [],
        MonthDayFirst : "",
        MonthDayLast : "",
        MonthDayFirstDayOfWeek : "",
        MonthDayFirstDayOfWeekPreArray : [],

        SelectedDateYear : "",
        SelectedDateMonth : "",
        SelectedDateDate : "",
        SelectedDateString : "", // YYYY-MM-DD
    };
    //   var daysOfWeek = {
    //     sunday: 0,
    //     monday: 1,
    //     tuesday: 2,
    //     wednesday: 3,
    //     thursday: 4,
    //     friday: 5,
    //     saturday: 6,
    //   };

  }

  changeHandler = value => {
    this.setState({ value });
    this.props.callback(value);
  };

  componentDidMount() {
    const defaultYear1 = this.props && this.props.defaultYear;
    const defaultMonth1 = this.props && this.props.defaultMonth;
    const defaultDate1 = this.props && this.props.defaultDate;

    console.log("defaultYear1", defaultYear1)
    console.log("defaultMonth1", defaultMonth1)
    console.log("defaultDate1", defaultDate1)

    this.__generateFirstDay(defaultYear1, defaultMonth1);

  }

  __generateFirstLastDayOfWeek = () => {
    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    
    var first = date.getDate() - date.getDay(); // First day is the day of the month - the day of the week
    var last = first + 6; // last day is the first day + 6
    
    var firstDayofWeek = new Date(date.setDate(first)).toUTCString();
    var lastdayOfWeek = new Date(date.setDate(last)).toUTCString();
    
    console.log(firstDayofWeek)    
    console.log(lastdayOfWeek)
  }

  __upperDateString = (paramString) => {
      var returnString1 = paramString;
      if (parseInt(returnString1) < 10 ) {
        return  "0" + parseInt(returnString1)
      } 
      return returnString1
  }

  __generateFirstDay = (paramYear, paramMonth) => {
    const monthString1 = paramYear + "-" + this.__upperDateString(paramMonth) + "-" + "01";

    // console.log("monthString1", monthString1)

    const startOfMonth = moment(monthString1, "YYYY-MM-DD").startOf('month').format('DD');
    const endOfMonth   = moment(monthString1, "YYYY-MM-DD").endOf('month').format('DD');

    // console.log("startOfMonth", startOfMonth)
    // console.log("endOfMonth", endOfMonth)

    const setDate = new Date(parseInt(paramYear), parseInt(paramMonth) - 1, 1);

    //getDate(): Get the day as a number (1-31)
    //이번 달의 첫째 날을 구합니다.
    const firstDay = setDate.getDate();
    //getDay(): Get the weekday as a number (0-6)
    //이번 달의 처음 요일을 구합니다.
    const firstDayName = setDate.getDay();

    // console.log("firstDay", firstDay)
    // console.log("firstDayName", firstDayName)

      //new Date(today.getFullYear(), today.getMonth(), 0);
      //Date객체의 day 인자에 0을 넘기면 지난달의 마지막 날이 반환됩니다.
      //new Date(today.getFullYear(), today.getMonth(), 1);
      //Date객체의 day 인자에 1을 넘기면 이번달 첫째 날이 반환됩니다.
      //이번 달의 마지막 날을 구합니다.
      const lastDay = new Date(
        setDate.getFullYear(),
        setDate.getMonth() + 1,
        0
      ).getDate();

      //지난 달의 마지막 날을 구합니다.
      const prevLastDay = new Date(
        setDate.getFullYear(),
        setDate.getMonth(),
        0
      ).getDate();
      // https://medium.com/duckuism/javascript로-이번-달-달력-만들기-1dff5f317459

      var array1 = [];
      var array2 = [];
      for (var i=0;i<parseInt(endOfMonth);i++ ) {
        array1.push({ day : i + 1, })
      }

      for (var i=0;i<parseInt(firstDayName);i++ ) {
        array2.push({ day : i + 1, })
      }


      this.setState({
        CurrentYear : paramYear,
        CurrentMonth: paramMonth,
        MonthArray: Array.from([...array1]),

        MonthDayFirst : "01",
        MonthDayLast : "" + parseInt(endOfMonth),
        MonthDayFirstDayOfWeek : firstDayName,
        MonthDayFirstDayOfWeekPreArray :  Array.from([...array2]),

      })

  }

  render() {
    const {
        CurrentYear, 
        CurrentMonth,
        MonthArray,
        MonthDayFirst, 
        MonthDayLast, 
        MonthDayFirstDayOfWeek,
        MonthDayFirstDayOfWeekPreArray,
        SelectedDateYear,
        SelectedDateMonth,
        SelectedDateDate,
    } = this.state;



    return (
        <>
        <Calendar1 
            className="calendar-select-wrapper1"
            onClick={e=> {
              e.preventDefault();
              e.stopPropagation();
            }}
        >
            <div className="month">      
                <ul>
                    <li className="prev" 
                    onClick={e=> {
                      e.preventDefault();
                      e.stopPropagation();
                        const year1 = CurrentYear;
                        const month1 = CurrentMonth;

                        const monthString1 = year1 + "-" + this.__upperDateString(month1) + "-" + "01";
                        const day1 = moment(monthString1, "YYYY-MM-DD").subtract(1, 'months');
                        const dayyear = day1.format("YYYY");
                        const daymonth = day1.format("MM");
                        
                        this.__generateFirstDay(dayyear,daymonth );

                    }}
                    >&#10094;</li>
                    <li className="next"
                    onClick={e=> {
                      e.preventDefault();
                      e.stopPropagation();
                        const year1 = CurrentYear;
                        const month1 = CurrentMonth;

                        const monthString1 = year1 + "-" + this.__upperDateString(month1) + "-" + "01";
                        const day1 = moment(monthString1, "YYYY-MM-DD").add(1, 'months');
                        const dayyear = day1.format("YYYY");
                        const daymonth = day1.format("MM");
                        
                        this.__generateFirstDay(dayyear,daymonth );

                    }}
                    >&#10095;</li>
                    <li>
                    {CurrentYear}
                    {' / '}
                    {CurrentMonth}
                    </li>
                </ul>
            </div>
            <ul className="weekdays">
                <li>일</li>
                <li>월</li>
                <li>화</li>
                <li>수</li>
                <li>목</li>
                <li>금</li>
                <li>토</li>
            </ul>

            <ul className="days">  
                {
                    MonthDayFirstDayOfWeekPreArray  &&
                    MonthDayFirstDayOfWeekPreArray.map((emptyItem, emptyIndex)=> {
                        return (
                            <li></li>   
                        )
                    })
                }
                {
                    MonthArray &&
                    MonthArray.map((dayItem, dayIndex)=> {
                        console.log("SelectedDateDate")
                        if (
                            SelectedDateYear == CurrentYear &&
                            SelectedDateMonth == CurrentMonth &&
                            dayItem.day == parseInt(SelectedDateDate)) {
                            return (
                                <li 
                                    onClick={()=> {
                                        console.log(" dayItem.day",  dayItem.day)
                                        this.setState({
                                            SelectedDateYear : CurrentYear,
                                            SelectedDateMonth : CurrentMonth,
                                            SelectedDateDate : dayItem.day,
                                        })
                                    }}
                                ><span className="active">{dayItem.day}</span></li>
                            )
                        }
                        return (<li
                            onClick={()=> {
                                console.log(" dayItem.day",  dayItem.day)
                                this.setState({
                                    SelectedDateYear : CurrentYear,
                                    SelectedDateMonth : CurrentMonth,
                                    SelectedDateDate : dayItem.day,
                                })
                                const dayString1 = CurrentYear + "-" + this.__upperDateString(CurrentMonth) + "-" + this.__upperDateString(dayItem.day) ;
                                this.props && this.props.callbackDate(dayString1)
                            }}
                            >{dayItem.day}</li>)

                    })
                }
            </ul>
        </Calendar1>
      <style jsx="true" global="true" suppressHydrationWarning>
          {`
        `}
      </style>
    </>
    );
  }
}
