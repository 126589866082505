import moment from "moment";
import axios from "axios";
import React, { useState, useRef, useContext, useEffect } from "react";
import { Store } from '../utils/Store';

import {NotificationContainer, NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';

import {FilterLine, FilterButton, FilterTextInput, FilterButtonSmall, FilterTextLabel, FilterCheckbox, FilterDropdownInput, FilterDateInput} from "../components/filter/Filter";
import PageTable from "../components/pageTable/PageTable";
import {Popup, PopupInputText, PopupButtonBig, PopupButtonSmall} from "../components/popup/Popup";

export default function ChallengePage() {
  // Authentication 
  const loginToken =  localStorage.getItem("parttimestydy_admin__access__token");
  const { state, dispatch } = useContext(Store);

  /////////////
  // Get List
  /////////////

  // Filter
  const [filterName, setFilterName] = useState("");
  const [filterPhone, setFilterPhone] = useState("");
  const [filterRefundDate, setFilterRefundDate] = useState("");
  const [filterRefunded, setFilterRefunded] = useState("");
  const [filterPageNum, setFilterPageNum] = useState(1);
  const [filterPageSize, setFilterPageSize] = useState(500);

  // ResultData
  const [resultDatas, setResultDatas] = useState([]);
  const [resultDataCount, setResultDataCount] = useState(0);
  const [resultDataPageNum, setResultDataPageNum] = useState(0);
  const [resultDataPageCount, setResultDataPageCount] = useState(0);

  // View Option
  const [viewOptionNum, setViewOptionNum] = useState(3);

  // View -> Filter
  const FilterComponent = <div style={{borderRadius:"10px", padding:"10px 10px", boxShadow:"0px 1px 5px lightgray"}}>
    <FilterLine>
      <FilterTextInput placeholder="이름" onChange={(e)=>{setFilterName(e.target.value)}} value={filterName}></FilterTextInput> 
      <FilterTextInput placeholder="전화번호" onChange={(e)=>{setFilterPhone(e.target.value)}} value={filterPhone}></FilterTextInput>  
    {/* </FilterLine>
    <FilterLine> */}
      <FilterDateInput placeholder="환급일자 (yyyy-mm-dd)" onChange={(e)=>{setFilterRefundDate(e.target.value)}} value={filterRefundDate} />  
      <FilterDropdownInput placeholder="환급여부 (true/false)" onChange={(e)=>{console.log(e);setFilterRefunded(e.target.value)}} value={filterRefunded} options={["", "true", "false"]}></FilterDropdownInput>  
      <FilterButton onClick={()=>{updateResultData()}}>조회</FilterButton>  
    </FilterLine>
    <FilterLine>
      {/* <FilterCheckbox checked={viewOptionNum == 1} onChange={e=>{if(e) setViewOptionNum(1); else setViewOptionNum(0);}}> 일정보이기</FilterCheckbox> */}
      <FilterDropdownInput
        placeholder="보기 옵션"
        options={["", "일정", "챌린지명", "개발용"]}
        value={
          viewOptionNum == 0 ? "" :
            viewOptionNum == 1 ? "일정" :
              viewOptionNum == 2 ? "챌린지명" :
              viewOptionNum == 3 ? "개발용" :
                ""
        }
        onChange={(e) => {
          e.target.value == "기본" ? setViewOptionNum(0) :
            e.target.value == "일정" ? setViewOptionNum(1) :
              e.target.value == "챌린지명" ? setViewOptionNum(2) :
              e.target.value == "개발용" ? setViewOptionNum(3) :
              // e.target.value == "데이터2" ? setViewOptionNum(3) :
                setViewOptionNum(0)
        }}
      />
    </FilterLine>
  </div>

  const filterPageComponent = <>
    <FilterLine justifyContent="center" >
      <FilterButtonSmall onClick={()=>{if(filterPageNum == 1 || filterPageNum == 2) return; setFilterPageNum(filterPageNum-2);}}>&lt;&lt;</FilterButtonSmall>  
      <FilterButtonSmall onClick={()=>{if(filterPageNum == 1) return; setFilterPageNum(filterPageNum-1);}}>&lt;</FilterButtonSmall>  
      <FilterTextLabel> {resultDataPageNum} / {resultDataPageCount} </FilterTextLabel>        
      <FilterButtonSmall onClick={()=>{if(filterPageNum >= resultDataPageCount) return; setFilterPageNum(filterPageNum+1);}}>&gt;</FilterButtonSmall>  
      <FilterButtonSmall onClick={()=>{if(filterPageNum >= resultDataPageCount-1) return; setFilterPageNum(filterPageNum+2);}}>&gt;&gt;</FilterButtonSmall>  
    </FilterLine>
  </>

  // Filter -> ResultData
  function updateResultData() {
    let filterQuery = `?pageSize=${filterPageSize}&pageNum=${parseInt(filterPageNum) - 1}`;
    if(filterName !== "") filterQuery += "&userName="+filterName;
    if(filterPhone !== "" && "010".indexOf(filterPhone) < 0 ) filterQuery += "&userPhone="+filterPhone;
    if(filterRefundDate !== "" && filterRefundDate.length == 10) filterQuery += "&returnDate="+filterRefundDate;
    if(filterRefunded == "true" || filterRefunded=="false") filterQuery += "&returned="+filterRefunded;
    let url = `${process.env.REACT_APP_SERVER_ROOT_URL}/admin/v4/challenge` + filterQuery;
    if (state.userToken == "" && loginToken != "") state.userToken = loginToken
    axios({ method: 'GET', url: url,
      headers: { "Authorization" : "Bearer " + state.userToken, "Content-Type": `application/json` }, 
      data: null,
    }).then(res => {
      if (res && res.data && res.data.content) {
        setResultDatas(res.data.content);
        setResultDataCount(res.data.totalElements);
        setResultDataPageCount(res.data.totalPages);
        setResultDataPageNum(filterPageNum);
      }
      else NotificationManager.error(res.message, "오류발생 ["+res.status+"] ");
    })
    .catch(e=> { NotificationManager.error(e.message, "오류 발생!");})
  }
  useEffect(()=> {if(filterPageNum == 1) updateResultData(); else setFilterPageNum(1)},[filterName, filterPhone, filterRefundDate, filterRefunded,filterPageSize ])
  useEffect(()=> {updateResultData()},[filterPageNum])
  

  const defaultThList = [ "번호", "결제 일시",
  "이름", "휴대폰",
  "결제 비용", "결제 현금 + 크레딧", "쿠폰 규칙명", "종료",
  "환급 완료일", "환급 현금 + 크레딧", "환급 구분",
  "상세"]
  const defaultDataKeyList = ["idx", "paidAt",
  "userName", "userPhone",
  "amountPaid", "payDesc", "usedCouponTemplateName", "finishedString",
  "returnedAt", "returnDesc", "returnedReason",
  "detail"]
  // ResultData -> View
  const resultDataComponent = <>
    {`총 데이터 수 : ${resultDataCount}, 총 페이지 수 : ${resultDataPageCount}`}
    <PageTable
      thList={
        viewOptionNum == 0 ? defaultThList
        : viewOptionNum == 1 ? [ 
            "번호","결제일시"
            ,"이름","휴대폰",
            "결제비용","결제현금+크레딧","쿠폰 규칙명",
            "최대 환급률","참여일정",  "환급 현금 + 크레딧", "환급 구분"
        ]
        : viewOptionNum == 2 ? [ 
            "번호","결제일시"
            ,"이름","휴대폰",
            "결제비용","결제현금+크레딧","쿠폰 규칙명",
            "최대 환급률","챌린지명",  "환급 현금 + 크레딧", "환급 구분"
        ]
        : viewOptionNum == 3? ["번호", "유저", "챌린지","결제","상태",  "환급", "상세"]
        :defaultThList
      }
      dataKeyList={
        viewOptionNum == 0 ? defaultDataKeyList
        : viewOptionNum == 1 ?[
            "idx", "paidAt", 
            "userName", "userPhone", 
            "amountPaid", "payDesc","usedCouponTemplateName",
            "maximumReturnPercent","participationDays",  "returnDesc", "returnedReason",
          ]
        : viewOptionNum == 2 ?[
            "idx", "paidAt", 
            "userName", "userPhone", 
            "amountPaid", "payDesc","usedCouponTemplateName",
            "maximumReturnPercent","challengeName",  "returnDesc", "returnedReason",
          ]
        : viewOptionNum == 3 ? ["idx", "userSummary", "challengeSummary","paymentSummary", "statusSummary",  "refundSummary", "detail"] 
        : defaultDataKeyList
      }
      dataList={resultDatas.map((value) => {



        value.finishedString = value.finished == true ? "O" : "-";
        value.amountPaid = value.amountPaid.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        value.amountPaidWithIamport = value.amountPaidWithIamport.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        value.amountPaidWithCredit = value.amountPaidWithCredit.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        value.payDesc = value.amountPaidWithIamport + " + " + value.amountPaidWithCredit;
        value.paidAt = moment(value.paidAt).format("YYYY-MM-DD HH:mm:ss")
        if (value.returnedAt != null) {
          value.returnedAt = moment(value.returnedAt).format("YYYY-MM-DD HH:mm:ss");
          if (value.amountReturned != null)
            value.amountReturned = value.amountReturned.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          if (value.amountReturnedWithIamport != null)
            value.amountReturnedWithIamport = value.amountReturnedWithIamport.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          value.amountReturnedWithCredit = (value.amountReturnedWithCredit != null) ? value.amountReturnedWithCredit.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "0";
          value.returnDesc = value.amountReturnedWithIamport + " + " + value.amountReturnedWithCredit;
        }

        value.maximumReturnPercent = value.maximumReturnBps
        if (value.maximumReturnPercent < 10000) value.maximumReturnPercent += 10000;
        value.maximumReturnPercent /= 10000;


        value.userSummary = value.userName == null? <>탈퇴한 유저</> : <>{value.userName}<br/>{value.userPhone}</>
        value.challengeSummary =  <div style={{paddingBlock: '16px'}}  >
          {value.challengeName}<br/>
          {"( "+value.participationDays+" )"}<br/>
          {"( "+value.endDate+" )"}
        </div>
        value.statusSummary = 
          value.status.includes("CANCEL")?<>❌<br/>CANCLED</>
          : value.status.includes("FINISHED") ? <>🏁<br/>{value.status}</>
          : value.status.includes("DEPOSIT_RETURNED") ? <>🏅<br/>{value.status}</>
          : value.status.includes("IN_PROGRESS") ? <>🏃<br/>{value.status}</>
          : value.status.includes("BEFORE_START") ? <>💼<br/>{value.status}</>
          : value.status
          
        value.paymentSummary = <div style={{paddingBlock: '16px'}}>
          {value.amountPaid}  <br/>
          {"("+value.amountPaidWithIamport + "+" + value.amountPaidWithCredit+") "}
          {value.usedCouponTemplateName == null ? <></>: <><br/>{value.usedCouponTemplateName}</>}
        </div>
        value.refundSummary = value.returnedAt == null?<>-</>:<div title={value.amountReturnedWithIamport + "+" + value.amountReturnedWithCredit}>
          {value.amountReturned}<br/>
          {"("+value.amountReturnedWithIamport + "+" + value.amountReturnedWithCredit+")"}<br/>
          {value.returnedReason}
        </div>

        value.detail = (
          <div className="button"
            onClick={() => {
              openResultDataSelectedPopup(value)
            }}>
            .
          </div>
        );
        return value;
      })}
    />
  </>


  /////////////
  // By One
  /////////////
  const [resultDataSelected, setResultDataSelected] = useState({});
  const [selectedPopupInputRefundAmount, setSelectedPopupInputRefundAmount] = useState("");
  const [selectedPopupInputSchedules, setSelectedPopupInputSchedules] = useState("");

  async function openResultDataSelectedPopup(value) {
    value.scheduleList = await getChallengeScheduleList(value.idx); 
    setSelectedPopupInputSchedules(""); 
    setSelectedPopupInputRefundAmount("");
    setResultDataSelected(value);
  }
  function closeSelectedPopupAndRefresh(){ setResultDataSelected({}); updateResultData(); }

  async function getChallengeScheduleList(challengeIdx){
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_ROOT_URL}/admin/v4/challenge/${challengeIdx}`
        ,{
          headers: {
            "Authorization": "Bearer " + state.userToken,
            "Content-Type": `application/json`
          },
        });
      return response.data.data.scheduleList;
    } catch (error) { NotificationManager.error(error.message, "오류발생 "); }
  }
 
  function refundAndCancelChallenge(){
    var url = `${process.env.REACT_APP_SERVER_ROOT_URL}/admin/v4/challenge/${resultDataSelected.idx}/cancel`;
    if (state.userToken == ""  && loginToken != "")  state.userToken = loginToken
    axios({ 
      method: 'PUT', url: url,
      headers: { "Authorization" : "Bearer " +  state.userToken, "Content-Type": `application/json`},
      data: {"refundAmount" : parseInt(selectedPopupInputRefundAmount)},
    }).then(res => {
      if(res.status < 200 || res.status >= 300){ 
        NotificationManager.error(res.message,"오류발생 ["+res.status+"] ");
        return;
      }
      NotificationManager.success("챌린지가 환불 및 취소 되었습니다.","환불되었습니다.");
      closeSelectedPopupAndRefresh(); 
    })
    .catch(e=> { NotificationManager.error(e.message,"오류발생");})
  }

  async function changeChallengeSchedule(){
    let scheduleData = { scheduleCodeList: selectedPopupInputSchedules.split("\n").map((e)=>{
      let code = e.split(" (")[0];
      return code;
    }).filter(e=> e != null)};

    if(scheduleData.scheduleCodeList.length == 0) {
        NotificationManager.error("일정을 비어있게 설정할 수 없습니다.","잘못된 입력");
      return;
    }

    try {
      const response = await axios.put(`${process.env.REACT_APP_SERVER_ROOT_URL}/admin/v4/challenge/${resultDataSelected.idx}/schedule`,
        scheduleData, 
        {headers: { "Authorization" : "Bearer " +  state.userToken, "Content-Type": `application/json`}}
      );
      closeSelectedPopupAndRefresh();
    } catch (error) {
      console.error(error);
        NotificationManager.error(error.message,"오류 발생");
    }

  }

  // Data -> View 
  const selectedPopUp = <Popup enabled={Object.keys(resultDataSelected).length} closeCallback={()=>{closeSelectedPopupAndRefresh()}}>
    <PopupInputText name="챌린지 번호" value={resultDataSelected.idx}></PopupInputText> <br/>
    <PopupInputText name="결제 일시" 
        value={resultDataSelected.paidAt && resultDataSelected.paidAt.split("T") && resultDataSelected.paidAt.split("T")[0]}
    /><br/>
    <PopupInputText name="반환 일시" 
        value={resultDataSelected.returnedAt && resultDataSelected.returnedAt.split("T") && resultDataSelected.returnedAt.split("T")[0]}
    /><br/>
    <PopupInputText name="반환 근거" value={resultDataSelected.returnedReason} /><br/>
    <PopupInputText name="참여요일" value={resultDataSelected.participationDays} /><br/>
    
    <div style={{height: '16px'}}></div>
    <PopupInputText name="환불 금액" value={selectedPopupInputRefundAmount} onChange={e=>{setSelectedPopupInputRefundAmount(e.target.value)}} width={'100px'}/>
    <PopupButtonSmall onClick={()=>{refundAndCancelChallenge()}}>환불 및 결제 취소</PopupButtonSmall><br/>
    <div style={{height: '16px'}}></div>

    <PopupInputText name="일정 변경" value={""} width={'0px'}/>
    <div style={{height: '150px', display: 'flex'}}>
      <textarea style={{width: '50%', height: '100%', overflowY:"scroll"}}
        value={resultDataSelected?.scheduleList?.map((e)=>{return e.code+" ("+e.achievement+"% 달성)";}).join("\n")}
      />
      <div> &gt;<br/>&gt;<br/> &gt;<br/>&gt;<br/> &gt;<br/>&gt;<br/> </div>
      <textarea style={{width: '50%', height: '100%',  overflowY:"scroll"}} 
        value={selectedPopupInputSchedules}
        onChange={(e)=>{setSelectedPopupInputSchedules(e.target.value);}} 
      />   
    </div>
    <div style={{height: '12px'}}></div>
    <PopupButtonBig onClick={()=>{changeChallengeSchedule()}}>일정 변경</PopupButtonBig>
  </Popup>


  /////////////
  // Create One
  /////////////

  return (
  <>
    {FilterComponent}
    <div style={{height:"20px"}}></div>
    {resultDataComponent} 
    <div style={{height:"10px"}}></div>
    {filterPageComponent}  
    {selectedPopUp} 
    <NotificationContainer />
  </>
  );
}
