import moment from "moment";
import axios from "axios";
import React, { useState, useRef, useContext, useEffect } from "react";
import { Store } from '../utils/Store';

import {NotificationContainer, NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';

import {FilterLine, FilterButton, FilterTextInput, FilterButtonSmall, FilterTextLabel, FilterCheckbox, FilterDropdownInput} from "../components/filter/Filter";
import PageTable from "../components/pageTable/PageTable";
import {Popup, PopupInputText, PopupButtonBig, PopupButtonSmall} from "../components/popup/Popup";
import { Filter } from "@material-ui/icons";

export default function PushMessagePage() {
  // Authentication 
  const loginToken =  localStorage.getItem("parttimestydy_admin__access__token");
  const { state, dispatch } = useContext(Store);

  /////////////
  // Get List
  /////////////

  // Filter
  const [filterPageNum, setFilterPageNum] = useState(1);
  const [filterPageSize, setFilterPageSize] = useState(30);

  // ResultData
  const [resultDatas, setResultDatas] = useState([]);
  const [resultDataCount, setResultDataCount] = useState(0);
  const [resultDataPageNum, setResultDataPageNum] = useState(0);
  const [resultDataPageCount, setResultDataPageCount] = useState(0);

  // View Option
  const [viewOptionNum, setViewOptionNum] = useState(0);

  // View -> Filter

  const FilterComponent = <div style={{borderRadius:"10px", padding:"10px 10px", boxShadow:"0px 1px 5px lightgray"}}>
    <FilterLine>
      <FilterButton onClick={()=>{openSendPushMessagePopup()}}>푸시메시지 전송하기</FilterButton>  
      <FilterButton onClick={()=>{window.open("http://grafana.partimestudy.com/d/q1AMe9L4z/W-uNsOydtO2EsF0g7Jyg7KCAIOyhsO2ajA?orgId=1", "_blank", "noreferrer")}}>푸시메시지 전송 타겟 구하기</FilterButton>  
    </FilterLine>
  </div>

  const filterPageComponent = <>
    <FilterLine justifyContent="center" >
      <FilterButtonSmall onClick={()=>{if(filterPageNum == 1 || filterPageNum == 2) return; setFilterPageNum(filterPageNum-2);}}>&lt;&lt;</FilterButtonSmall>  
      <FilterButtonSmall onClick={()=>{if(filterPageNum == 1) return; setFilterPageNum(filterPageNum-1);}}>&lt;</FilterButtonSmall>  
      <FilterTextLabel> {resultDataPageNum} / {resultDataPageCount} </FilterTextLabel>        
      <FilterButtonSmall onClick={()=>{if(filterPageNum >= resultDataPageCount) return; setFilterPageNum(filterPageNum+1);}}>&gt;</FilterButtonSmall>  
      <FilterButtonSmall onClick={()=>{if(filterPageNum >= resultDataPageCount-1) return; setFilterPageNum(filterPageNum+2);}}>&gt;&gt;</FilterButtonSmall>  
    </FilterLine>
  </>

  // Filter -> ResultData
  function updateResultData() {
    setResultDatas([])
    setResultDataCount(0);
    setResultDataPageCount(0);
    setResultDataPageNum(filterPageNum);
  }

  useEffect(()=> {if(filterPageNum == 1) updateResultData(); else setFilterPageNum(1)},[filterPageSize ])
  useEffect(()=> {updateResultData()},[filterPageNum])
  

  const defaultThList = ["빈 테이블"]
  const defaultDataKeyList = []

  const resultDataComponent = <>
    {`총 데이터 수 : ${resultDataCount}, 총 페이지 수 : ${resultDataPageCount}`}
    <PageTable
      thList={
        viewOptionNum == 0 ? defaultThList
        :defaultThList
      }
      dataKeyList={
        viewOptionNum == 0 ? defaultDataKeyList
        : defaultDataKeyList
      }
      dataList={resultDatas.map((value) => {
        return value;
      })}
    />
  </>
  

  //////////////
  // Send Push Message Popup
  //////////////
  const [sendPushMessagePopup, setSendPushMessagePopup] = useState(true);
  
  const [sendPushMessagePopupInputTitle, setSendPushMessagePopupInputTitle] = useState("");
  const [sendPushMessagePopupInputContent, setSendPushMessagePopupInputContent] = useState("");
  const [sendPushMessagePopupInputPhoneListString, setSendPushMessagePopupInputPhoneListString] = useState("");


  function openSendPushMessagePopup(){ setSendPushMessagePopup(true) }
  function closeSendPushMessagePopup(){ setSendPushMessagePopup(false)}

  function sendPushMessage(){
    let url = `${process.env.REACT_APP_SERVER_ROOT_URL}/v2.0/admin/user`;
    if (state.userToken == "" && loginToken != "") state.userToken = loginToken
    closeSendPushMessagePopup(); 
    var phoneList = sendPushMessagePopupInputPhoneListString.split("\n").filter((elem)=> elem !='');
    axios({ 
      method: 'POST', url: `${process.env.REACT_APP_SERVER_ROOT_URL}/v3.0/admin/notification/push-message`,
      headers: {  "Authorization" : "Bearer " + loginToken, "Content-Type": `application/json`},
      data: {
        contentsTitle : sendPushMessagePopupInputTitle,
        contentsBody : sendPushMessagePopupInputContent,
        userPhoneList : phoneList
      }
    }).then((res)=>{
      if(res.status < 200 || res.status >= 300){ 
        NotificationManager.error(res.message,"오류발생 ["+res.status+"] ");
        return;
      }
      NotificationManager.success("푸시메시지가 전송되었습니다","전송되었습니다!");
    })
    .catch(e=> { NotificationManager.error(e.message,"오류발생");})
  }
  const sendPushMessagePopupComponent = <Popup enabled={sendPushMessagePopup} closeCallback={()=>{closeSendPushMessagePopup()}}>
    <PopupInputText name={"제목"}  width={"0"}/> <br/> 
    <textarea  style={{marginInline:"auto", width:"100%", height: "40px", boxSizing:"border-box", fontSize:"16px"}}
      value={sendPushMessagePopupInputTitle} onChange={(e)=>{setSendPushMessagePopupInputTitle(e.target.value);}}
      placeholder="오늘 하루도 화이팅~~"
    ></textarea>
    <PopupInputText name={"내용"} width={"0px"} />  <br/>
    <textarea  style={{marginInline:"auto", width:"100%", height: "50px", boxSizing:"border-box", fontSize:"16px"}}
      value={sendPushMessagePopupInputContent} onChange={(e)=>{setSendPushMessagePopupInputContent(e.target.value);}} 
      placeholder="여러분 모두 열공하세요~!!"
    ></textarea>
    <PopupInputText name={"타겟 전화번호 리스트"} width={"0px"}/>  <br/>
    <textarea  style={{marginInline:"auto", width:"100%", height: "100px", boxSizing:"border-box", fontSize:"16px", overflowY:"auto"}}
      value={sendPushMessagePopupInputPhoneListString} onChange={(e)=>{setSendPushMessagePopupInputPhoneListString(e.target.value)}}
      placeholder={"010-1234-5678\n010-3333-3333\n01099999999\n..."}
    ></textarea>
    <div style={{height: '16px'}}></div>
    <PopupButtonBig onClick={()=>{sendPushMessage()}}>
      {sendPushMessagePopupInputPhoneListString.split("\n").filter(e=> e.trim()!="").length}명에게 메시지 전송
    </PopupButtonBig>
  </Popup>

  return (
  <>
    {FilterComponent}
    <div style={{height:"20px"}}></div>
    {resultDataComponent} 
    <div style={{height:"10px"}}></div>
    {filterPageComponent}  
    {sendPushMessagePopupComponent}
    <NotificationContainer />
  </>
  );
}
