import moment from "moment";
import axios from "axios";
import React, { useState, useRef, useContext, useEffect } from "react";
import { Store } from '../utils/Store';

import {NotificationContainer, NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';

import {FilterLine, FilterButton, FilterTextInput, FilterButtonSmall, FilterTextLabel, FilterCheckbox, FilterDropdownInput} from "../components/filter/Filter";
import PageTable from "../components/pageTable/PageTable";
import {Popup, PopupInputText, PopupButtonBig, PopupButtonSmall} from "../components/popup/Popup";
import { Filter } from "@material-ui/icons";

export default function RecordPage() {
  // Authentication 
  const loginToken =  localStorage.getItem("parttimestydy_admin__access__token");
  const { state, dispatch } = useContext(Store);

  /////////////
  // Get List
  /////////////

  // Filter
  const [filterPageNum, setFilterPageNum] = useState(1);
  const [filterPageSize, setFilterPageSize] = useState(30);

  // ResultData
  const [resultDatas, setResultDatas] = useState([]);
  const [resultDataCount, setResultDataCount] = useState(0);
  const [resultDataPageNum, setResultDataPageNum] = useState(0);
  const [resultDataPageCount, setResultDataPageCount] = useState(0);

  // View Option
  const [viewOptionNum, setViewOptionNum] = useState(0);

  // View -> Filter

  const FilterComponent = <div style={{borderRadius:"10px", padding:"10px 10px", boxShadow:"0px 1px 5px lightgray"}}>
    <FilterLine>
      <FilterButton onClick={()=>{updateResultData()}}>조회</FilterButton>  
      <FilterButton onClick={()=>{openDisableBlockPopup()}}>재가입 차단 해제</FilterButton>  
    </FilterLine>
  </div>

  const filterPageComponent = <>
    <FilterLine justifyContent="center" >
      <FilterButtonSmall onClick={()=>{if(filterPageNum == 1 || filterPageNum == 2) return; setFilterPageNum(filterPageNum-2);}}>&lt;&lt;</FilterButtonSmall>  
      <FilterButtonSmall onClick={()=>{if(filterPageNum == 1) return; setFilterPageNum(filterPageNum-1);}}>&lt;</FilterButtonSmall>  
      <FilterTextLabel> {resultDataPageNum} / {resultDataPageCount} </FilterTextLabel>        
      <FilterButtonSmall onClick={()=>{if(filterPageNum >= resultDataPageCount) return; setFilterPageNum(filterPageNum+1);}}>&gt;</FilterButtonSmall>  
      <FilterButtonSmall onClick={()=>{if(filterPageNum >= resultDataPageCount-1) return; setFilterPageNum(filterPageNum+2);}}>&gt;&gt;</FilterButtonSmall>  
    </FilterLine>
  </>

  // Filter -> ResultData
  function updateResultData() {
    let filterQuery = `?pageSize=${filterPageSize}&pageNum=${parseInt(filterPageNum) - 1}`;
    let url = `${process.env.REACT_APP_SERVER_ROOT_URL}/v2.0/admin/user-deleted` + filterQuery;
    if (state.userToken == "" && loginToken != "") state.userToken = loginToken
    axios({ method: 'GET', url: url,
      headers: { "Authorization" : "Bearer " + state.userToken, "Content-Type": `application/json` }, 
      data: null,
    }).then(res => {
      if (res && res.data && res.data.content) {
        setResultDatas(res.data.content);
        setResultDataCount(res.data.totalElements);
        setResultDataPageCount(res.data.totalPages);
        setResultDataPageNum(filterPageNum);
      }
      else NotificationManager.error(res.message, "오류발생 ["+res.status+"] ");
    })
    .catch(e=> { NotificationManager.error(e.message, "오류 발생!");})
  }

  function updateResultDataRow(value){ 
    var origin1 = [...resultDatas];
    var index1 = resultDatas.findIndex((iter) => {if (iter.idx == value.idx) { return iter}});
    origin1[index1] = value
    setResultDatas(origin1);
  }
  useEffect(()=> {if(filterPageNum == 1) updateResultData(); else setFilterPageNum(1)},[filterPageSize ])
  useEffect(()=> {updateResultData()},[filterPageNum])
  

  const defaultThList = ["탈퇴 시각", "탈퇴 이유", "탈퇴 의견",]
  const defaultDataKeyList = [ "deletedAt", "deletedReason", "deletedSuggestion"]

  const resultDataComponent = <>
    {`총 데이터 수 : ${resultDataCount}, 총 페이지 수 : ${resultDataPageCount}`}
    <PageTable
      thList={viewOptionNum == 0 ? defaultThList:defaultThList}
      dataKeyList={viewOptionNum == 0 ? defaultDataKeyList: defaultDataKeyList}
      dataList={resultDatas.map((value) => {return value;})}
    />
  </>

  const [disableBlockPopup, setDisableBlockPopup] = useState(0);
  const [disableBlockUserPhoneListString, setDisableBlockUserPhoneListString] = useState("");

  function openDisableBlockPopup(){setDisableBlockPopup(true);}
  function closeDisableBlockPopup(){setDisableBlockPopup(false);}

  function disableBlockUser() {
    let url = `${process.env.REACT_APP_SERVER_ROOT_URL}/v2.0/admin/user-deleted/block-register`;
    if (state.userToken == "" && loginToken != "") state.userToken = loginToken
    let phoneList = disableBlockUserPhoneListString.split("\n"); // TODO Trim, filter ""
    axios({
      method: 'PUT', url: url,
      headers: { "Authorization": "Bearer " + state.userToken, "Content-Type": `application/json` },
      data: { "phones": phoneList},
    }).then(res => {
      if (res.status < 200 || res.status >= 300) {
        NotificationManager.error(res.message, "오류발생 [" + res.status + "] ");
        return;
      }
      NotificationManager.success(res.data.length + "명의 재가입 제한이 해지되었습니다.", "재가입 제한이 해지되었습니다.");
      closeDisableBlockPopup();
    })
    .catch(e => { NotificationManager.error(e.message, "오류발생"); throw e; })
  }

  const disableBlockPopupComponent = <Popup enabled={disableBlockPopup} closeCallback={()=>{closeDisableBlockPopup()}}>
    <PopupInputText name={"재가입 해제할 유저 리스트"} width={"0px"}/>  <br/>
    <textarea  style={{marginInline:"auto", width:"100%", height: "100px", boxSizing:"border-box", fontSize:"16px", overflowY:"auto"}}
      value={disableBlockUserPhoneListString} onChange={(e)=>{setDisableBlockUserPhoneListString(e.target.value)}}
      placeholder={"010-1234-5678\n010-3333-3333\n01099999999\n..."}
    ></textarea>

    <PopupButtonBig onClick={()=>{disableBlockUser()}}>재가입 해제</PopupButtonBig>

    {/* <PopupInputText name="이름" value={createPopupInputName}  onChange={e=>{setCreatePopupInputName(e.target.value)}}/><br/>
    <PopupInputText name="닉네임" value={createPopupInputNickname}  onChange={e=>{setCreatePopupInputNickname(e.target.value)}}/><br/>
    <PopupInputText name="전화번호" placeholder={"기존에 가입된 전화번호로 계정생성은 불가능합니다."} value={createPopupInputPhone}  onChange={e=>{setCreatePopupInputPhone(e.target.value)}}/><br/>
    <PopupInputText name="성별" placeholder={"ETC / WOMAN / MAN"} value={createPopupInputGender}  onChange={e=>{setCreatePopupInputGender(e.target.value)}}/><br/>
    <PopupInputText name="생일" placeholder={"2000-10-21"} value={createPopupInputBirth}  onChange={e=>{setCreatePopupInputBirth(e.target.value)}}/><br/>
    <PopupInputText name="공부목표" value={createPopupInputStudyGoal} onChange={e=>{setCreatePopupInputStudyGoal(e.target.value)}}/><br/>
    <PopupInputText name="이메일" placeholder={"기존에 가입된 이메일로 계정생성은 불가능합니다."} value={createPopupInputEmail} onChange={e=>{setCreatePopupInputEmail(e.target.value)}}/><br/>
    <PopupInputText name="비밀번호" value={createPopupInputPassword} onChange={(e)=>{setCreatePopupInputPassword(e.target.value);}}/> <br/>
    <div style={{height: '16px'}}></div>
    <PopupButtonBig onClick={()=>{createUser()}}>유저 생성</PopupButtonBig> */}
  </Popup>

  return (
  <>
    {FilterComponent}
    <div style={{height:"20px"}}></div>
    {resultDataComponent} 
    <div style={{height:"10px"}}></div>
    {filterPageComponent}  
    {disableBlockPopupComponent}
    <NotificationContainer />
  </>
  );
}
