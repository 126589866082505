import moment from "moment";
import axios from "axios";
import React, { useState, useRef, useContext, useEffect } from "react";
import { Store } from '../utils/Store';

import {NotificationContainer, NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';

import {FilterLine, FilterButton, FilterTextInput, FilterButtonSmall, FilterTextLabel, FilterCheckbox, FilterDropdownInput} from "../components/filter/Filter";
import PageTable from "../components/pageTable/PageTable";
import {Popup, PopupInputText, PopupButtonBig, PopupButtonSmall} from "../components/popup/Popup";


import V2ChallengeCouponTemplate from "./2023071709_challenge_coupon_templates"
import V2ChallengeCouponCreationCode from "./2023071710_challenge_coupon_creation_code"


export default function ChallengePage() {
  // Authentication 
  const loginToken =  localStorage.getItem("parttimestydy_admin__access__token");
  const { state, dispatch } = useContext(Store);

  /////////////
  // Get List
  /////////////

  // Filter
  const [filterName, setFilterName] = useState("");
  const [filterPhone, setFilterPhone] = useState("");
  const [filterCouponTemplateName, setFilterCouponTemplateName] = useState("");
  const [filterPageNum, setFilterPageNum] = useState(1);
  const [filterPageSize, setFilterPageSize] = useState(300);

  // ResultData
  const [resultDatas, setResultDatas] = useState([]);
  const [resultDataCount, setResultDataCount] = useState(0);
  const [resultDataPageNum, setResultDataPageNum] = useState(0);
  const [resultDataPageCount, setResultDataPageCount] = useState(0);

  // View Option
  const [viewOptionNum, setViewOptionNum] = useState(0);

  // View -> Filter
  const FilterComponent = <div style={{borderRadius:"10px", padding:"10px 10px", boxShadow:"0px 1px 5px lightgray"}}>
    <FilterLine>
      <FilterButton onClick={()=>{openCouponTemplatePopup()}}>쿠폰규칙조회</FilterButton>  
      <FilterButton onClick={()=>{openCouponCreationCodePopup()}}>쿠폰생성코드조회</FilterButton>  
    </FilterLine>
    <FilterLine>
      <FilterTextInput placeholder="이름" onChange={(e)=>{setFilterName(e.target.value)}} value={filterName}></FilterTextInput> 
      <FilterTextInput placeholder="전화번호" onChange={(e)=>{setFilterPhone(e.target.value)}} value={filterPhone}></FilterTextInput>  
      <FilterTextInput placeholder="쿠폰규칙명" onChange={(e)=>{setFilterCouponTemplateName(e.target.value)}} value={filterCouponTemplateName}></FilterTextInput>  
      <FilterButton onClick={()=>{updateResultData()}}>조회</FilterButton>  
    </FilterLine>
  </div>

  const filterPageComponent = <>
    <FilterLine justifyContent="center" >
      <FilterButtonSmall onClick={()=>{if(filterPageNum == 1 || filterPageNum == 2) return; setFilterPageNum(filterPageNum-2);}}>&lt;&lt;</FilterButtonSmall>  
      <FilterButtonSmall onClick={()=>{if(filterPageNum == 1) return; setFilterPageNum(filterPageNum-1);}}>&lt;</FilterButtonSmall>  
      <FilterTextLabel> {resultDataPageNum} / {resultDataPageCount} </FilterTextLabel>        
      <FilterButtonSmall onClick={()=>{if(filterPageNum >= resultDataPageCount) return; setFilterPageNum(filterPageNum+1);}}>&gt;</FilterButtonSmall>  
      <FilterButtonSmall onClick={()=>{if(filterPageNum >= resultDataPageCount-1) return; setFilterPageNum(filterPageNum+2);}}>&gt;&gt;</FilterButtonSmall>  
    </FilterLine>
  </>

  // Filter -> ResultData
  function updateResultData() {
    let filterQuery = `?pageSize=${filterPageSize}&pageNum=${parseInt(filterPageNum) - 1}`;
    if(filterName !== "") filterQuery += "&userName="+filterName;
    if(filterPhone !== "" && "010".indexOf(filterPhone) < 0 ) filterQuery += "&userPhone="+filterPhone;
    if(filterCouponTemplateName !== "") filterQuery += "&couponTemplateName="+filterCouponTemplateName;
    let url = `${process.env.REACT_APP_SERVER_ROOT_URL}/v3.0/admin/study-project-coupon` + filterQuery;
    if (state.userToken == "" && loginToken != "") state.userToken = loginToken
    axios({ method: 'GET', url: url,
      headers: { "Authorization" : "Bearer " + state.userToken, "Content-Type": `application/json` }, 
      data: null,
    }).then(res => {
      if (res && res.data && res.data.content) {
        setResultDatas(res.data.content);
        setResultDataCount(res.data.totalElements);
        setResultDataPageCount(res.data.totalPages);
        setResultDataPageNum(filterPageNum);
      }
      else NotificationManager.error(res.message, "오류발생 ["+res.status+"] ");
    })
    .catch(e=> { NotificationManager.error(e.message, "오류 발생!");})
  }
  useEffect(()=> {if(filterPageNum == 1) updateResultData(); else setFilterPageNum(1)},[filterName, filterPhone, filterCouponTemplateName,filterPageSize ])
  useEffect(()=> {updateResultData()},[filterPageNum])
  

  const defaultThList = [ 
    "번호",
    "사용자 이름", "사용자 번호",
    "쿠폰 기한", "쿠폰 규칙명", "쿠폰 이름"
    , "사용 여부", "쿠폰 코드"
]
  const defaultDataKeyList =  [
    "idx",
    "userName", "userPhone",
    "expireDateTimeString",
    "couponTemplateName",
    "couponName"
    , "usedAt"
    , "creationCodeKeyString"
  ]
  // ResultData -> View
  const resultDataComponent = <>
    {`총 데이터 수 : ${resultDataCount}, 총 페이지 수 : ${resultDataPageCount}`}
    <PageTable
      thList={
        viewOptionNum == 0 ? defaultThList
        :defaultThList
      }
      dataKeyList={
        viewOptionNum == 0 ? defaultDataKeyList
        : defaultDataKeyList
      }
      dataList={resultDatas.map((value) => {
        value.expireDateTimeString = moment(value.expireDateTime).format("YYYY-MM-DD HH:mm:ss")
        value.usedString = value.used === true ? "사용" : "-";
        value.creationCodeKeyString = value.creationCodeKey === null ? "-" : value.creationCodeKey;
        return value;
      })}
    />
  </>


  /////////////
  // Coupon Templates
  /////////////
  const [couponTemplatePopup, setCouponTemplatePopup] = useState(false);
  function openCouponTemplatePopup(){ setCouponTemplatePopup(true);}
  const couponTemplatePopupComponent = <Popup enabled={couponTemplatePopup} closeCallback={()=>{setCouponTemplatePopup(false)}}>
    <V2ChallengeCouponTemplate/>
  </Popup>
  /////////////
  // Coupon Creation Codes
  /////////////
  const [couponCreationCodePopup, setCouponCreationCodePopup] = useState(false);
  function openCouponCreationCodePopup(){ setCouponCreationCodePopup(true);}
  const couponCreationCodePopupComponent = <Popup enabled={couponCreationCodePopup} closeCallback={()=>{setCouponCreationCodePopup(false)}}>
    <V2ChallengeCouponCreationCode/>
  </Popup>




  return (
  <>
    {FilterComponent}
    <div style={{height:"20px"}}></div>
    {resultDataComponent} 
    <div style={{height:"10px"}}></div>
    {filterPageComponent}  
    {couponTemplatePopupComponent} {couponCreationCodePopupComponent}
    <NotificationContainer />
  </>
  );
}
