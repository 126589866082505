import React, { createContext, useReducer, useContext, } from 'react';
import Cookies from 'js-cookie';

export const Store = createContext();
const initialState = {
  layoutMode: 'desktop',
  languageMode : Cookies.get('languageMode') && Cookies.get('languageMode') === 'EN' ? 'EN' : 'KR',
  darkMode: Cookies.get('darkMode') === 'ON' ? true : false,
  page : {
    currentLoaded : '',
    previousLoaded : '/',
  },
  popup : {
    load : false,
    blur : false,
    code : '',
    object : null,
  },
  userInfo: Cookies.get('userInfo')
    ? JSON.parse(Cookies.get('userInfo'))
    : null,
  userToken : ''
};

function reducer(state, action) {
  switch (action.type) {
    case 'LANG_MODE_EN':
      return  { ...state, languageMode: 'EN' };
    case 'LANG_MODE_KR':
      return  { ...state, languageMode: 'KR' };
    case 'DARK_MODE_ON':
      return { ...state, darkMode: true };
    case 'DARK_MODE_OFF':
      return { ...state, darkMode: false };
    case 'USER_LOGIN':
      return { ...state, userInfo: action.payload };
    case 'USER_LOGOUT':

      sessionStorage.removeItem('parttimestydy_admin__access__token');
      sessionStorage.removeItem('parttimestydy_admin__auto__sign');
      sessionStorage.removeItem('parttimestydy_admin__sign__date');
      localStorage.removeItem('parttimestydy_admin__access__token');
      localStorage.removeItem('parttimestydy_admin__auto__sign');
      localStorage.removeItem('parttimestydy_admin__sign__date');

      return {
        ...state,
        userInfo: null,
        userToken : "",
      };
      

    default:
      return state;
  }
}


export function StoreProvider(props) {
    const [state, dispatch] = useReducer(reducer, initialState);
    const value = { state, dispatch };

    // const router = useRouter();
    // if (state.page.currentLoaded != state.page.previousLoaded) {
    //   state.page.previousLoaded = state.page.currentLoaded 
    // } else {

    // }
    // state.page.currentLoaded = router.pathname;

    return <Store.Provider value={value}>{props.children}</Store.Provider>;
}
