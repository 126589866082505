
import "./topbar.css";
import { NotificationsNone, Language, Settings } from "@material-ui/icons";
import React, { useState, useRef, useContext, useEffect } from "react";
import { Store } from '../../utils/Store';


export default function Topbar(props) {
  const loginToken =  localStorage.getItem("parttimestydy_admin__access__token");
  // console.log("Topbar = props");
  // console.log(props);
  const { state, dispatch } = useContext(Store);
  const [ userToken, setUserToken] = useState("");

  useEffect(()=> {
    if (state.userToken == null || state.userToken === undefined || typeof state.userToken == 'undefined' || state.userToken == "") { 
      setUserToken("");
    } else {
      setUserToken(state.userToken);
    }
  }, [])

  return (
    <div className="topbar">
      <div className="topbarWrapper">
        <div className="topLeft">
          <span className="logo"> PTS Admin</span>
        </div>
        <div className="topRight">
          {
            props.is_login == false && 
            <>
            </>
          }
          {
            props.is_login == true && 
            <>
              <div className="topbarIconContainer" 
                onClick={()=> {
                  localStorage.removeItem("parttimestydy_admin__access__token");
                  localStorage.clear();
                  window.location.href = "/";
                }}
              >
                {/* <Settings /> */}
                Logout
              </div>
              {/* <img src={"https://picsum.photos/200"} alt="" className="topAvatar" /> */}
            </>
          }
        </div>
      </div>
    </div>
  );
}
