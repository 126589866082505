import moment from "moment";
import axios from "axios";
import React, { useState, useRef, useContext, useEffect } from "react";
import { Store } from '../utils/Store';

import {NotificationContainer, NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';

import {FilterLine, FilterButton, FilterTextInput, FilterButtonSmall, FilterTextLabel, FilterCheckbox, FilterDropdownInput} from "../components/filter/Filter";
import PageTable from "../components/pageTable/PageTable";
import {Popup, PopupInputText, PopupButtonBig, PopupButtonSmall} from "../components/popup/Popup";

export default function ChallengePage() {
  // Authentication 
  const loginToken =  localStorage.getItem("parttimestydy_admin__access__token");
  const { state, dispatch } = useContext(Store);

  /////////////
  // Get List
  /////////////

  // Filter
  const [filterPageNum, setFilterPageNum] = useState(1);
  const [filterPageSize, setFilterPageSize] = useState(300);

  // ResultData
  const [resultDatas, setResultDatas] = useState([]);
  const [resultDataCount, setResultDataCount] = useState(0);
  const [resultDataPageNum, setResultDataPageNum] = useState(0);
  const [resultDataPageCount, setResultDataPageCount] = useState(0);

  // View Option
  const [viewOptionNum, setViewOptionNum] = useState(0);

  // View -> Filter

  const filterPageComponent = <>
    <FilterLine justifyContent="center" >
      <FilterButtonSmall onClick={()=>{if(filterPageNum == 1 || filterPageNum == 2) return; setFilterPageNum(filterPageNum-2);}}>&lt;&lt;</FilterButtonSmall>  
      <FilterButtonSmall onClick={()=>{if(filterPageNum == 1) return; setFilterPageNum(filterPageNum-1);}}>&lt;</FilterButtonSmall>  
      <FilterTextLabel> {resultDataPageNum} / {resultDataPageCount} </FilterTextLabel>        
      <FilterButtonSmall onClick={()=>{if(filterPageNum >= resultDataPageCount) return; setFilterPageNum(filterPageNum+1);}}>&gt;</FilterButtonSmall>  
      <FilterButtonSmall onClick={()=>{if(filterPageNum >= resultDataPageCount-1) return; setFilterPageNum(filterPageNum+2);}}>&gt;&gt;</FilterButtonSmall>  
    </FilterLine>
  </>

  // Filter -> ResultData
  function updateResultData() {
    let filterQuery = `?pageSize=${filterPageSize}&pageNum=${parseInt(filterPageNum) - 1}`;
    let url = `${process.env.REACT_APP_SERVER_ROOT_URL}/v2.0/admin/coupon` + filterQuery;
    if (state.userToken == "" && loginToken != "") state.userToken = loginToken
    axios({ method: 'GET', url: url,
      headers: { "Authorization" : "Bearer " + state.userToken, "Content-Type": `application/json` }, 
      data: null,
    }).then(res => {
      if (res && res.data && res.data.content) {
        setResultDatas(res.data.content);
        setResultDataCount(res.data.totalElements);
        setResultDataPageCount(res.data.totalPages);
        setResultDataPageNum(filterPageNum);
      }
      else NotificationManager.error(res.message, "오류발생 ["+res.status+"] ");
    })
    .catch(e=> { NotificationManager.error(e.message, "오류 발생!");})
  }
  useEffect(()=> {if(filterPageNum == 1) updateResultData(); else setFilterPageNum(1)},[filterPageSize ])
  useEffect(()=> {updateResultData()},[filterPageNum])
  

  const defaultThList = [ 
            "번호","쿠폰 규칙명/ 쿠폰명",
            "조건",
            "혜택",
            "부여하기"
]
  const defaultDataKeyList =  [
            "idx", "templateNameAndCouponName",
            "conditions",
            "benefits",
            "grant"
  ]
  // ResultData -> View
  const resultDataComponent = <>
    {`총 데이터 수 : ${resultDataCount}, 총 페이지 수 : ${resultDataPageCount}`}
    <PageTable
      thList={
        viewOptionNum == 0 ? defaultThList
        :defaultThList
      }
      dataKeyList={
        viewOptionNum == 0 ? defaultDataKeyList
        : defaultDataKeyList
      }
      dataList={resultDatas.map((value) => {
        value.templateNameAndCouponName = <div style={{ paddingBlock: "10px" }}><font style={{color:"#00000033"}}>{value.templateName}</font><br />{value.name}</div>
        value.conditions = <div style={{ paddingBlock: "10px" }}>
          {value.availablePeriodDescription} (사용기간)<br />
          {
            value.studyProjectDepositAmountRangeMax == value.studyProjectDepositAmountRangeMin
              ? value.studyProjectDepositAmountRangeMax
              : (value.studyProjectDepositAmountRangeMin + "~" + value.studyProjectDepositAmountRangeMax)
          } (보증금)<br />
          {value.studyProjectScheduleConstraint} (일정 규약)
        </div>;
        value.benefits = <div style={{ paddingBlock: "10px" }}>
          {value.discountRate != null ? value.discountRate + " %" : value.discountAmount + " 원"} (할인)<br />
          {value.refundRateBonusBps / 100}% (환급 추가)<br />
          {
            value.studyProjectRefundType == "EXCEPT_DISCOUNT" ? "할인 금액 제외 환급"
              : value.studyProjectRefundType == "EXCEPT_DISCOUNT_FOR_ACHV_90_99PCT" ? "할인 금액 제외(90%~99%) 환급"
                : "할인 포함 환급"
          }<br />
        </div>;
        value.enabledString = (value.enabled == true) ? "가능" : "불가 (개발 중)";
        value.grant = (<div className="button" onClick={() => { openResultDataSelectedPopup(value) }}>.</div>);
        return value;
      })}
    />
  </>

  const [resultDataSelected, setResultDataSelected] = useState({});
  const [selectedPopupInputGrantUserPhoneList, setSelectedPopupInputGrantUserPhoneList] = useState("");

  async function openResultDataSelectedPopup(value) {
    setResultDataSelected(value);
    setSelectedPopupInputGrantUserPhoneList("");
  }

  function closeSelectedPopupAnRefresh() {
    setResultDataSelected({});
    updateResultData();
  }


  function grantCoupon() {
    let url = `${process.env.REACT_APP_SERVER_ROOT_URL}/v3.0/admin/study-project-coupon`;
    if (state.userToken == "" && loginToken != "") state.userToken = loginToken
    let phoneList = selectedPopupInputGrantUserPhoneList.split("\n"); // TODO Trim, filter ""
    axios({
      method: 'POST', url: url,
      headers: { "Authorization": "Bearer " + state.userToken, "Content-Type": `application/json` },
      data: { "couponTemplateIdx": resultDataSelected.idx, "phone": phoneList},
    }).then(res => {
      if (res.status < 200 || res.status >= 300) {
        NotificationManager.error(res.message, "오류발생 [" + res.status + "] ");
        return;
      }
      NotificationManager.success(res.data.length+"명에게 부여되었습니다.", "부여되었습니다.");
      closeSelectedPopupAnRefresh();
    })
    .catch(e => { NotificationManager.error(e.message, "오류발생"); throw e; })
  }

  const selectedPopUp = <Popup enabled={Object.keys(resultDataSelected).length} closeCallback={() => { closeSelectedPopupAnRefresh(resultDataSelected) }}>
  {/* <PopupInputText name="번호" value={resultDataSelected.idx}></PopupInputText> <br /> */}
  <PopupInputText name="쿠폰명" value={resultDataSelected.name}></PopupInputText> <br />
  <PopupInputText name="부여할 유저 전화번호" value={selectedPopupInputGrantUserPhoneList} width={'0px'}/><br/>
    <textarea
      // disabled={true} 
      style={{ width: "98%", height: "100px", overflowY: "auto", fontSize:"14px" }}
      placeholder="010-1234-1234"
      value={selectedPopupInputGrantUserPhoneList}
      onChange={(e) => { setSelectedPopupInputGrantUserPhoneList(e.target.value) }}
    />
    <PopupButtonBig onClick={()=>{grantCoupon()}}>쿠폰 부여</PopupButtonBig>
</Popup>


  return (
  <>
    {filterPageComponent}
    {resultDataComponent} 
    <div style={{height:"10px"}}></div>
    {filterPageComponent}  
    {selectedPopUp}
    <NotificationContainer />
  </>
  );
}
